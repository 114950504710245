/** @jsxImportSource @emotion/react */
import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
} from "react";
import { observer } from "mobx-react-lite";
import { useAppUserIdentityType } from "../../../hooks";
import { css } from "@emotion/react";
import { ArrowIcon, Card, Typography } from "../../../components";
import {
  GetHomeFormListPromiseType,
  HomeFormListType,
} from "../../../models/entity/followUpForm";
import usePatientStore from "../../../store/patient";
import Empty from "../../../coreUIComponents/Empty";
import { Divider, Ellipsis } from "antd-mobile";
import { useDoctorRouter } from "../../../router/Doctor";
import { usePatientRouter } from "../../../router/Patient";
import { basePath as doctorBasePath } from "../../../router/Doctor/config";
import { basePath as patientBasePath } from "../../../router/Patient/config";
import formIcon from "../../../assets/icons/formIcon.svg";

const AccentColorTypography = observer(function (
  props: PropsWithChildren<{
    text: number;
  }>
) {
  const { text } = props;
  return (
    <Typography
      className="ml-2 mr-2"
      type="title"
      css={css`
        color: #e34d59;
      `}
    >
      {text}
    </Typography>
  );
});

const CountDown = observer(function (
  props: PropsWithChildren<{
    time: string;
  }>
) {
  const { time } = props;
  const arr = time.split("-");
  const now = new Date();
  const endDate = new Date(
    Number(arr[0]),
    Number(arr[1]) - 1,
    Number(arr[2]),
    24
  );
  let leftTime = endDate.getTime() - now.getTime();
  if (leftTime <= 0) {
    leftTime = 0;
  }

  let leftSecond = parseInt(String(leftTime / 1000));
  const day = Math.floor(leftSecond / (60 * 60 * 24));
  const hour = Math.floor((leftSecond - day * 24 * 60 * 60) / 3600);
  const minute = Math.floor(
    (leftSecond - day * 24 * 60 * 60 - hour * 3600) / 60
  );

  return (
    <Typography type="mini" className="p-0">
      <Typography
        className="mr-2"
        type="title"
        css={css`
          color: #e34d59;
        `}
      >
        {day}
      </Typography>
      天
      <AccentColorTypography text={hour} />
      时
      <AccentColorTypography text={minute} />
      分后截止填写
    </Typography>
  );
});

export default observer(function Form() {
  const routerDoctor = useDoctorRouter();
  const routerPatient = usePatientRouter();
  const patientStore = usePatientStore();
  const appUserIdentityType = useAppUserIdentityType();

  const isDoctor = useMemo(() => {
    return appUserIdentityType === "doctor";
  }, [appUserIdentityType]);

  const router = useMemo(() => {
    return isDoctor ? routerDoctor : routerPatient;
  }, [isDoctor, routerDoctor, routerPatient]);

  const { userLogicStore, followUpFormStore, followUpOutpatientStore } =
    useMemo(() => patientStore, [patientStore]);

  const userProfile = useMemo(
    () => userLogicStore.userProfile,
    [userLogicStore.userProfile]
  );

  const { homeFormList } = followUpFormStore;
  const { selectedOpcProject } = followUpOutpatientStore;

  useEffect(() => {
    if (!selectedOpcProject.projectId) return;
    followUpFormStore.getHomeFormList({
      empiOrUserid: userProfile?.empiOrUserid || "",
      type: isDoctor ? 2 : 1,
      projectId: selectedOpcProject.projectId,
    });
  }, [followUpFormStore, isDoctor, selectedOpcProject.projectId, userProfile?.empiOrUserid]);

  const path = useMemo(() => {
    return isDoctor ? doctorBasePath : patientBasePath;
  }, [isDoctor]);

  const handleClick = useCallback(
    async (item: GetHomeFormListPromiseType, formItem: HomeFormListType) => {
      console.log("formItem.formType", formItem.formType);

      const base = process.env.REACT_APP_BASE_VISIT_PREFIX ?? "/";

      const url = {
        pathname: `${base}visit${path}followUpMiniProgramFormGatner`,
        // pathname: `/nfmssxbd/visit${path}followUpMiniProgramFormGatner`,
        search: `?formId=${formItem.formId}&formAttrs=${JSON.stringify({
          isDisabled: false,
          hideOperationButtons: false,
        })}&formOrigin=FollowUp&payload=${JSON.stringify({
          id: "",
          formName: formItem.formName,
          nodeId: formItem.nodeId,
          nodeTime: item.nodeTime,
          nodeName: item.nodeName,
          projectId: formItem.projectId,
          patientId: item.patientId,
          formType: formItem.formType,
        })}`,
      };

      router.navigateTo(url);
    },
    [path, router]
  );

  return (
    <>
      {homeFormList.length > 0 ? (
        homeFormList.map((item: GetHomeFormListPromiseType, index: number) => {
          return (
            <div key={index} className="p-3 mt-1">
              <Card className="p-0">
                <div className="p-3 pb-3">
                  <Typography block className="mb-3" type="title-bold">
                    {item.projectName}
                  </Typography>

                  <Typography block className="mb-3">
                    {item.nodeName}
                  </Typography>

                  <CountDown time={item.nodeTime} />
                </div>

                <Divider
                  css={css`
                    margin: 0;
                  `}
                />

                {!!item.forms.length &&
                  item.forms.map(
                    (formItem: HomeFormListType, formIndex: number) => {
                      return (
                        <div
                          key={formIndex}
                          onClick={() => handleClick(item, formItem)}
                        >
                          <div className="flex justify-between items-center">
                            <div
                              className="flex items-center p-2"
                              css={css`
                                width: 80%;
                              `}
                            >
                              <img className="w-6 h-6" src={formIcon} alt="" />
                              <div>
                                <Typography
                                  type="title"
                                  css={css`
                                    font-weight: normal;
                                  `}
                                >
                                  <Ellipsis
                                    direction="end"
                                    rows={1}
                                    content={formItem.formName || ""}
                                  />
                                </Typography>
                              </div>
                            </div>

                            <Typography
                              css={css`
                                color: #86909c;
                                width: 20%;
                              `}
                            >
                              去填写
                              <ArrowIcon
                                direction="right"
                                css={css`
                                  font-size: 14px;
                                `}
                              />
                            </Typography>
                          </div>

                          <Divider
                            css={css`
                              margin: 0;
                            `}
                          />
                        </div>
                      );
                    }
                  )}
              </Card>
            </div>
          );
        })
      ) : (
        <Empty title="暂无待填写表单" />
      )}
    </>
  );
});
