import { makeAutoObservable } from "mobx";
import { serviceClient } from "../../service";
import { hospitalHttpInstance, saasHttpInstance } from "../../utils/http";
import { UserInfo, UserProfile } from "../../models/entity/user";
import Cookies from "js-cookie";
import { getAppUserIdentityType, setSaasTokenByUrl } from "../../utils";

const appUserIdentityType = getAppUserIdentityType();

export const URL_TOKEN_KEY = "token";

export const SAAS_TOKEN_KEY =
  appUserIdentityType === "doctor" ? "DOCTOR_SAAS_TOKEN" : "PATIENT_SAAS_TOKEN";
export const SAAS_HTTP_HEADER_TOKEN_KEY = "Authorization";

export const HOSPITAL_TOKEN_KEY = "HOSPITAL_TOKEN";
export const HOSPITAL_HTTP_HEADER_TOKEN_KEY = "Authorization";

export const HOSPITAL_COOKIE_KEY = "srp-token";

export const HOSPITAL_CODE_KEY = "HOSPITAL_CODE";

// export const WX_AUTHOR_INFO = "wx-author-info";
class UserLogicStore {
  userProfile = null as UserProfile | null;
  userInfo = null as UserInfo | null;
  saasToken = null as string | null;

  constructor() {
    makeAutoObservable(this);
  }
  fetchUserProfile = async () => {
    const _userProfile = await serviceClient.user.getUserProfile();
    this.initHospitalTokenAndRequestInfo(_userProfile);
    this.userProfile = _userProfile;
  };
  fetchUserInfo = async () => {
    const userInfo = await serviceClient.user.getUserInfo(
      !window._settings?.isWXLogin
    );

    this.userInfo = userInfo;

    if (!window._settings?.isWXLogin) {
      this.userProfile = {
        empiOrUserid: this.userInfo?.empiOrUserid || "",
        type: this.userInfo?.type,
        wxPhoneNumber: this.userInfo?.wxPhoneNumber,
        hospitalUrl: this.userInfo?.hospitalUr,
      };

      Cookies.set(HOSPITAL_COOKIE_KEY, this.saasToken || "");
      sessionStorage.setItem(HOSPITAL_TOKEN_KEY, this.saasToken || "");
    }
  };
  initSaasToken() {
    const saasTokenByUrl = setSaasTokenByUrl();
    const saasToken = saasTokenByUrl ?? localStorage.getItem(SAAS_TOKEN_KEY);

    if (saasToken) {
      saasHttpInstance.defaults.headers[HOSPITAL_HTTP_HEADER_TOKEN_KEY] =
        saasToken;

      if (!window._settings?.isWXLogin) {
        hospitalHttpInstance.defaults.headers[HOSPITAL_HTTP_HEADER_TOKEN_KEY] =
          saasToken;
      }

      this.saasToken = saasToken;
    }
  }
  initHospitalTokenAndRequestInfo(props: {
    hospitalCode?: string;
    hospitalToken?: string;
    hospitalUrl?: string;
  }) {
    const { hospitalCode, hospitalToken, hospitalUrl } = props;

    window._settings.hospitalWebUrl = hospitalUrl ?? "";

    if (hospitalToken) {
      sessionStorage.setItem(HOSPITAL_TOKEN_KEY, hospitalToken);
      Cookies.set(HOSPITAL_COOKIE_KEY, hospitalToken);
      hospitalHttpInstance.defaults.headers[HOSPITAL_HTTP_HEADER_TOKEN_KEY] =
        hospitalToken;
    }

    if (hospitalCode) {
      sessionStorage.setItem(HOSPITAL_CODE_KEY, hospitalCode);
      hospitalHttpInstance.defaults.baseURL = `/api/${hospitalCode}`;
      window._settings.hospitalCode = hospitalCode;
    }
  }
  async initializeUserInfo() {
    this.initSaasToken();
    try {
      if (this.saasToken) {
        if (window._settings.isWXLogin) {
          await this.fetchUserProfile();
        }

        await this.fetchUserInfo();
      } else {
        throw Error();
      }
    } catch (error) {
      this.logout();
    }
  }

  // getWxAuthorInfo() {
  //   return JSON.parse(Cookies.get(WX_AUTHOR_INFO) || "{}");
  // }
  // setWxAuthorInfo(info: any) {
  //   Cookies.set(WX_AUTHOR_INFO, JSON.stringify(info), { expires: 30 });
  // }

  logout() {
    this.userProfile = {} as UserProfile;
    this.userInfo = {} as UserInfo;
    Cookies.remove(HOSPITAL_COOKIE_KEY);
    this.clearStorage();
    // const base = process.env.REACT_APP_NODE_ENV === "outer" ? "/nfmssxsf" : "/";

    const base = process.env.REACT_APP_BASE_PREFIX ?? "/";

    window.location.href = base;
  }

  clearStorage() {
    localStorage.clear();
    sessionStorage.clear();
  }
}

export default UserLogicStore;
