/** @jsxImportSource @emotion/react */
import { observer, useLocalObservable } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import useDoctorStore from "../../store/doctor";
import { css } from "@emotion/react";
import { ArrowIcon, Button, CheckList, Typography } from "../../components";
import { runInAction } from "mobx";
import { Popup } from "antd-mobile";
import { CheckListItem, CheckListValueType } from "../../components/CheckList";
import { useTheme } from "../../theme";
import { saveImg } from "../../utils/handleImg";

export default observer(function FollowUpProjectQRCode() {
  const { appLogicStore, userLogicStore } = useDoctorStore();
  let [popupVisible, setPopupVisible] = useState(false);
  const theme = useTheme();
  const QRCodePath = `${process.env.REACT_APP_BASE_PREFIX}/asset/followUpProjectQRCode.png`;

  const {
    projectList,
    selectedProject,
    selectedProjectId,
    setSelectedProjectId,
  } = useLocalObservable(() => ({
    get projectList() {
      return userLogicStore.userInfo?.list;
    },
    get selectedProject() {
      return this.projectList?.find(
        (item) => item.followProId === this.selectedProjectId
      );
    },
    selectedProjectId: undefined as string | undefined,
    setSelectedProjectId(value: string) {
      this.selectedProjectId = value;
    },
  }));

  const onChangeProject = useCallback(
    (value: string) => {
      setSelectedProjectId(value);
      setPopupVisible(false);
    },
    [setSelectedProjectId]
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSaveImg = useCallback(() => {
    saveImg(QRCodePath);
  }, [QRCodePath]);

  useEffect(() => {
    appLogicStore.setTitle("");
  }, [appLogicStore]);

  useEffect(() => {
    runInAction(() => {
      setSelectedProjectId(projectList?.[0]?.followProId ?? "");
    });
  }, [projectList, setSelectedProjectId]);

  return (
    <div
      className="px-4 pt-7"
      css={css`
        background-color: #ebedf0;
        height: 100%;
        overflow: auto;
      `}
    >
      <div
        css={css`
          background-color: ${theme.boxBackgroundColor};
        `}
        className="p-4"
        onClick={() => setPopupVisible(true)}
      >
        <Typography type="title">{selectedProject?.followProName}</Typography>
        {(projectList?.length ?? 0) > 1 && (
          <ArrowIcon
            direction="down"
            css={css`
              float: right;
            `}
          />
        )}
      </div>
      <img
        src={QRCodePath}
        alt=""
        css={css`
          width: 100%;
          display: block;
        `}
      />
      <Typography
        type="mini"
        className="pt-3 pb-7 text-center"
        block
        css={css`
          background-color: ${theme.whiteColor};
        `}
      >
        扫描上面的二维码，打开随访小程序
      </Typography>
      <div className="text-center mt-16">
        <Button
          size="mini"
          fill="none"
          color="primary"
          className="pt-16 text-center"
        >
          长按保存二维码
        </Button>
      </div>
      <Popup visible={popupVisible} onMaskClick={() => setPopupVisible(false)}>
        <CheckList
          className="px-4"
          value={selectedProjectId}
          onChange={onChangeProject as (value: CheckListValueType) => void}
        >
          {projectList?.map((item) => (
            <CheckListItem value={item?.followProId} key={item.followProId}>
              {item.followProName}
            </CheckListItem>
          ))}
        </CheckList>
      </Popup>
    </div>
  );
});
