/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import AppMain from "./AppMain";
import AppFooter from "./AppFooter";
import "./layout.css";
import { observer } from "mobx-react-lite";
import { usePatientRouter } from "../../router/Patient";
import usePatientStore from "../../store/patient";
import { Fragment, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { registerMicroApps } from "qiankun";
import { SafeArea } from "antd-mobile";

export default observer(function Layout() {
  const { userLogicStore, appLogicStore } = usePatientStore();
  const router = usePatientRouter();
  const currentRouter = router.currentRouter();
  const { userInfo, userProfile } = userLogicStore;

  const formEditorUrl = useMemo(() => {
    let _url = userProfile?.hospitalUrl;
    if (process.env.REACT_APP_NODE_ENV === "development") {
      _url = _url?.replace("https", "http");
    }
    return _url;
  }, [userProfile?.hospitalUrl]);

  useEffect(() => {
    userLogicStore.initializeUserInfo();
  }, [userLogicStore]);

  useEffect(() => {
    if (currentRouter?.title) {
      appLogicStore.setTitle(currentRouter.title);
    }
  }, [appLogicStore, currentRouter?.title]);

  useEffect(() => {
    if (formEditorUrl) {
      const activeRulePath = `${process.env.REACT_APP_BASE_PREFIX}${
        process.env.REACT_APP_BASE_VISIT_PREFIX ?? "/"
      }visit`.replace(/\/{2,}/g, "/");

      registerMicroApps([
        {
          name: "visit",
          entry: formEditorUrl,
          // entry: "http://192.168.9.89:80", //todo 本地测试
          container: "#formGather",
          activeRule: activeRulePath, // 激活的路径
        },
      ]);
    }
  }, [formEditorUrl]);

  if (!userInfo) {
    return null;
  }

  return (
    <Fragment>
      <Helmet>
        <title>{appLogicStore.title}</title>
      </Helmet>
      <div
        css={css`
          height: 100vh;
          display: flex;
          flex-direction: column;
        `}
      >
        <AppMain />
        {currentRouter?.isShowFooterTabBar && <AppFooter />}
        <SafeArea position="bottom" />
      </div>
    </Fragment>
  );
});
