/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useCallback, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import SearchItem from "./SearchItem";
import { SearchBar, Typography } from "../../../components";
import DropdownTitle from "../../DropdownSelect/DropdownTitle";
import { useAppUserIdentityType } from "../../../hooks";
import { useDoctorRouter } from "../../../router/Doctor";
import { usePatientRouter } from "../../../router/Patient";
import { basePath as doctorBasePath } from "../../../router/Doctor/config";
import { basePath as patientBasePath } from "../../../router/Patient/config";
import { FormListType } from "../../../models/entity/followUpProject";

export default React.memo(function SearchSubmitPatient() {
  const { state } = useLocation();
  const routerDoctor = useDoctorRouter();
  const routerPatient = usePatientRouter();
  const appUserIdentityType = useAppUserIdentityType();
  const { formId, type, gatherList, payload } = state || {};

  const [keyword, setKeyword] = useState<string>("");

  const router = useMemo(() => {
    return appUserIdentityType === "doctor" ? routerDoctor : routerPatient;
  }, [appUserIdentityType, routerDoctor, routerPatient]);

  const path = useMemo(() => {
    return appUserIdentityType === "doctor" ? doctorBasePath : patientBasePath;
  }, [appUserIdentityType]);

  const list = useMemo(() => {
    const _gatherList = JSON.parse(gatherList) || [];
    return _gatherList?.filter((item: FormListType) => {
      return (
        (item?.patientName && item?.patientName.indexOf(keyword) > -1) ||
        (item?.patientId && item?.patientId?.indexOf(keyword) > -1)
      );
    });
  }, [keyword, gatherList]);

  const toFormBrowse = useCallback(
    (data: FormListType) => {
      const _payload = JSON.parse(payload);
      router.navigateTo(
        {
          // pathname: `/nfmssxbd/visit${path}followUpMiniProgramFormGatner`,
          pathname: `${process.env.REACT_APP_BASE_VISIT_PREFIX}visit${path}followUpMiniProgramFormGatner`,
          search: `?formId=${data.formId || formId}&formAttrs=${JSON.stringify({
            isDisabled: true,
            hideOperationButtons: true,
          })}&formOrigin=${type}&payload=${JSON.stringify({
            ..._payload,
            id: data?.id,
            formType: data?.formType,
          })}`,
        },
        { replace: true }
      );
    },
    [formId, path, payload, router, type]
  );

  const handleClick = useCallback(() => {
    const _payload = JSON.parse(payload);
    toFormBrowse(_payload);
  }, [payload, toFormBrowse]);

  const itemClick = useCallback(
    (val: FormListType) => {
      toFormBrowse(val);
    },
    [toFormBrowse]
  );

  return (
    <>
      <div
        className="flex items-center p-4"
        css={css`
          background-color: rgba(247, 248, 250, 0.6);
        `}
      >
        <SearchBar
          value={keyword}
          className="flex-1"
          placeholder="请输入患者姓名、患者号"
          onChange={(val) => {
            // val.replace(/\s+/g, "")
            var _trim = new RegExp(/\s+/g);
            if (_trim.test(val)) {
              return;
            }
            setKeyword(val);
          }}
        />
        <div className="px-2" onClick={handleClick}>
          <Typography colorType="graySecondaryColor" block>
            取消
          </Typography>
        </div>
      </div>
      <div className="page-padding">
        <DropdownTitle
          type="title"
          block
          title={"已提交患者"}
          className="mb-2"
        />

        {list?.map((item: FormListType, index: number) => {
          return (
            <React.Fragment key={item.id}>
              <SearchItem
                className="py-3"
                itemValue={item}
                keyword={keyword}
                onclick={() => itemClick(item)}
              />
            </React.Fragment>
          );
        })}
      </div>
    </>
  );
});
