/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import React, { useCallback, useMemo } from "react";
import { Popup } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { Typography, Button } from "../../components";
import { CheckCircleFill } from "antd-mobile-icons";
import { useAppUserIdentityType } from "../../hooks";
import { useDoctorRouter } from "../../router/Doctor";
import { usePatientRouter } from "../../router/Patient";
import { basePath as doctorBasePath } from "../../router/Doctor/config";
import { basePath as patientBasePath } from "../../router/Patient/config";

export default React.memo(function SubmitSuccessPage({
  onVisibleHandle,
  popupVisible,
  nextData,
}: {
  onVisibleHandle: (value: boolean) => void;
  popupVisible: boolean;
  nextData: Record<string, any>;
}) {
  const {
    type,
    nodeName,
    formId,
    formType,
    currentFormName,
    formName,
    patientId,
    projectId,
    nodeId,
    time,
    userName,
  } = nextData || {};
  const navigate = useNavigate();
  const routerDoctor = useDoctorRouter();
  const routerPatient = usePatientRouter();
  const appUserIdentityType = useAppUserIdentityType();

  const isDoctor = useMemo(() => {
    return appUserIdentityType === "doctor";
  }, [appUserIdentityType]);

  const router = useMemo(() => {
    return isDoctor ? routerDoctor : routerPatient;
  }, [isDoctor, routerDoctor, routerPatient]);

  const path = useMemo(() => {
    return isDoctor ? doctorBasePath : patientBasePath;
  }, [isDoctor]);

  const handleClick = useCallback(async () => {
    if (formId) {
      onVisibleHandle(false);

      router.navigateTo(
        {
          // pathname: `/nfmssxbd/visit${path}followUpMiniProgramFormGatner`,
          pathname: `${
            process.env.REACT_APP_BASE_VISIT_PREFIX ?? "/"
          }visit${path}followUpMiniProgramFormGatner`,
          search: `?formId=${formId}&formAttrs=${JSON.stringify({
            isDisabled: false,
            hideOperationButtons: false,
          })}&formOrigin=${type}&payload=${JSON.stringify({
            id: "",
            formName,
            formType,
            nodeId,
            time,
            nodeName,
            projectId,
            patientId,
            userName,
          })}`,
        },
        { replace: true }
      );
    } else {
      onVisibleHandle(false);
      // navigate(-1);
      isDoctor
        ? navigate(-1)
        : routerPatient.navigateTo("home", { replace: true });
    }
  }, [
    formId,
    formName,
    formType,
    isDoctor,
    navigate,
    nodeId,
    nodeName,
    onVisibleHandle,
    path,
    patientId,
    projectId,
    router,
    routerPatient,
    time,
    type,
    userName,
  ]);

  return (
    <Popup
      visible={popupVisible}
      onMaskClick={() => {
        onVisibleHandle(false);
      }}
      bodyStyle={{ height: "100vh" }}
      css={css`
        --z-index: 100000;
      `}
    >
      <div className="page-padding flex flex-col pt-32 pb-24 h-full">
        <div className="flex flex-col flex-auto justify-start items-center">
          <CheckCircleFill fontSize={72} color="#00B42A" />
          <Typography
            block
            className="mt-4"
            css={css`
              font-weight: 500;
            `}
          >
            {currentFormName}提交成功
          </Typography>
          <Typography
            block
            className="mt-4"
            css={css`
              font-weight: 500;
            `}
          >
            感谢您按时提交表单！
          </Typography>
        </div>
        <div className="w-full flex flex-col justify-center items-center">
          <div className="mb-4">
            {formId ? (
              <>
                <Typography type="secondary" colorType="grayLightColor">
                  下一份待填写表单：
                </Typography>
                <Typography>{formName}</Typography>
              </>
            ) : (
              <Typography type="secondary" colorType="grayLightColor">
                暂无待填写表单
              </Typography>
            )}
          </div>
          <Button block color="primary" fill="solid" onClick={handleClick}>
            {formId ? "继续填写" : "返回首页"}
          </Button>
        </div>
      </div>
    </Popup>
  );
});
