import { BrowserRouter } from "react-router-dom";
import Layout from "./pagesPatient/Layout";
import { rootStore, StoreContext } from "./store/patient";
import { setSaasTokenByUrl } from "./utils";

function AppPatient() {
  setSaasTokenByUrl();

  const base = process.env.REACT_APP_BASE_PREFIX ?? "/";

  return (
    <BrowserRouter basename={base}>
      <StoreContext.Provider value={rootStore}>
        <Layout />
      </StoreContext.Provider>
    </BrowserRouter>
  );
}

export default AppPatient;
